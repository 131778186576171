<template>
    <section>
      <div class="w-full rounded-lg shadow-md p-4">
        <div class="w-full border-b-2 border-blue-600 ">
          <p class="text-blue-600 font-medium text-md">
            Datos Empleado
          </p>
        </div>
        <div class="w-full grid grid-cols-2 gap-4 mt-4 lg:grid-cols-3">
          <div>
            <label class="text-gray-600 text-xs">Tipo de contratación:</label>
            <p class="font-bold mt-1">
              {{contrato.name}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Fecha inicio:</label>
            <p class="font-bold mt-1">
                {{dataContrato.start_date}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Fecha fin:</label>
            <p class="font-bold mt-1">
                {{dataContrato.retirement_date ? dataContrato.retirement_date : '--'}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Area:</label>
            <p class="font-bold mt-1">
                {{area}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Sub area:</label>
            <p class="font-bold mt-1">
                {{subArea.name}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Sede:</label>
            <p class="font-bold mt-1">
                {{sede}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Eps:</label>
            <p class="font-bold mt-1">
                {{empleado.Eps}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Afp:</label>
            <p class="font-bold mt-1">
                {{empleado.Afp}}
            </p>
          </div>
          <div>
            <label class="text-gray-600 text-xs">Ccf:</label>
            <p class="font-bold mt-1">
                {{empleado.Ccf}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import empleadosStore from '../../../../../stores/empleados.store'
  import { computed } from 'vue'
  import dayjs from 'dayjs'

  export default {
    name: 'ComponentEmployeeData',
    setup () {
      const empleado = computed(() => empleadosStore.getters._dataEmpleado)
      const contrato = computed(() => empleadosStore.getters._contrato)
      const dataContrato = computed(() => empleadosStore.getters._dataContratos)
      const area = computed(() => empleadosStore.getters._area)
      const subArea = computed(() => empleadosStore.getters._sub_area)
      const sede = computed(() => empleadosStore.getters._sede)
      return {
        dayjs,
        empleado,
        contrato,
        dataContrato,
        area,
        subArea,
        sede
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  ::v-deep(.p-dropdown-trigger) {
    display: none;

  }
  ::v-deep(.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
    background-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
    border-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
}
  </style>
